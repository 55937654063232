import Vue from 'vue';
import Vuex from 'vuex';
import account from './modules/account';
import app from './modules/app';
import courier from './modules/courier';
import invoice from './modules/invoice';
import label from './modules/label';
import plan from './modules/plan';
import order from './modules/order';
import shop from './modules/shop';
import auth from './modules/auth';
import ticket from './modules/ticket';
import { UnauthorizedError } from '@/utils/errors';

Vue.use(Vuex);

function authenticationHandlerPlugin(store) {
  const dispatch = store.dispatch;

  store.dispatch = async (type, payload) => {
    try {
      return await dispatch.apply(store, [type, payload]);
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        await dispatch.apply(store, ['auth/login']);
        return await dispatch.apply(store, [type, payload]);
      } else {
        throw e;
      }
    }
  };
}

export default new Vuex.Store({
  plugins: [authenticationHandlerPlugin],
  state: {
    appInitialized: false,
  },
  mutations: {
    setAppInitialized(state, value) {
      state.appInitialized = value;
    },
  },
  modules: {
    account: {
      namespaced: true,
      ...account,
    },
    app: {
      namespaced: true,
      ...app,
    },
    courier: {
      namespaced: true,
      ...courier,
    },
    plan: {
      namespaced: true,
      ...plan,
    },
    label: {
      namespaced: true,
      ...label,
    },
    order: {
      namespaced: true,
      ...order,
    },
    shop: {
      namespaced: true,
      ...shop,
    },
    auth: {
      namespaced: true,
      ...auth,
    },
    invoice: {
      namespaced: true,
      ...invoice,
    },
    ticket: {
      namespaced: true,
      ...ticket,
    },
  },
  actions: {
    async init({ dispatch }) {
      await dispatch('auth/init');
    },
    async afterLogin({ dispatch }) {
      // Wait, because we can't init anything else without the user
      await dispatch('account/init');
      dispatch('app/init');
      await dispatch('courier/init');
      await dispatch('shop/init');
      dispatch('plan/init');
      if (process.env.VUE_APP_IS_UVDESK_ENABLED === true) dispatch('ticket/init');
      dispatch('initializeApp');
    },
    initializeApp({ commit }) {
      commit('setAppInitialized', true);
    },
  },
  strict: process.env.NODE_ENV !== 'production',
});
