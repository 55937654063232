import { api } from '@/utils';
import hash from 'object-hash';
import { reportGenericError } from '@/utils/functions';
import { AbortPrintException } from '@/utils/errors';
import router from '@/router';

const state = {
  all: [],
  views: {
    untrackedTab: { isLoading: false, data: null, update: null, total: 0 },
    trackedTab: { isLoading: false, data: null, update: null, total: 0 },
    deliveredTab: { isLoading: false, data: null, update: null, total: 0 },
    returnTab: { isLoading: false, data: null, update: null, total: 0 },
  },
};

const getters = {
  getLabelById: state => labelId => state.all.find(o => o.id === labelId),
  getAllLabels: state => state.all,
};

const actions = {
  async retryOrderUpdate(store, labelIds) {
    try {
      await api.retryOrderUpdate(labelIds);
    } catch (e) {
      console.error(e);
    }
  },

  // Get labels
  async getLabel({ state, commit }, { labelId, forceReload = false }) {
    if (!forceReload) {
      let label = state.all.find(o => o.id === labelId);
      if (label) return label;
    }
    let result = await api.getLabel(labelId);
    commit('setLabel', result.data);
    return result.data;
  },
  async getLabels({ commit }, filters) {
    // Still in use for invoices
    const result = await api.getLabel(filters);
    commit('setLabels', result.data);
    return result;
  },
  async checkCanCreateLabel({ rootState }, totalLabelsToCreate) {
    const organisation = rootState.account.organisation;

    if (!organisation?.can_create_labels) {
      reportGenericError({
        title: 'Je hebt helaas achterstallige betalingen in je account.',
        message:
          'Je kunt geen zendingen meer aanmaken totdat de facturen zijn betaald. Bel of mail ons gerust voor vragen op 050 211 09 22 of administratie@innosend.eu.',
        confirmText: 'Naar facturen',
        cancelText: 'Annuleren',
        onConfirm: () => router.push({ name: 'billing' }),
      });
      throw new AbortPrintException('Achterstallige betalingen gevonden');
    }

    const quota = organisation?.plan?.quota?.labels;

    const labelsPrinted = rootState.account.labelCount;
    if (quota && totalLabelsToCreate + labelsPrinted > quota) {
      reportGenericError({
        title: 'Limiet bereikt!',
        message: `Je hebt ${labelsPrinted} van de ${quota} labels gebruikt. Je wilt er nu ${totalLabelsToCreate} aanmaken. Dit overschrijdt je limiet. Upgrade je abonnement om meer dan ${quota} labels te kunnen maken.`,
        confirmText: 'Naar abonnement',
        cancelText: 'Annuleren',
        onConfirm: () => router.push({ name: 'plans' }),
      });
      throw new AbortPrintException('Limiet bereikt');
    }

    if (process.env.VUE_APP_SHOULD_CHECK_FOR_PAYMENT && organisation?.payment?.status !== 'ready') {
      reportGenericError({
        title: 'Geen automatische incasso',
        message: 'Er is geen automatische incasso ingesteld. Ga naar de instellingen om dit in te stellen.',
        cancelText: 'Annuleren',
        confirmText: 'Instellingen',
        onConfirm: () => router.push({ name: 'collection' }),
      });

      throw new AbortPrintException('Geen automatische incasso');
    }
  },
  /**
   * Update or create labels
   * @param commit - Vuex commit
   * @param dispatch - Vuex dispatch
   * @param orderIds - Array of order IDs or single order ID
   * @param isReturn - Boolean, whether to create return labels
   * @param force - Boolean, whether to force label creation
   * @param scan - Boolean, whether the orders were scanned
   * @returns {Promise<any>}
   */
  async createLabels({ commit, dispatch }, { orderIds, isReturn = false, force = false }) {
    await dispatch('checkCanCreateLabel', orderIds.length);

    if (!Array.isArray(orderIds)) orderIds = [orderIds];

    const response = await api.createLabels(orderIds, force, isReturn);

    if (response.status === 'success') {
      commit('setLabels', response.data);
      dispatch('reloadViews', { silent: false });

      dispatch('order/reloadViews', { silent: false }, { root: true });
      commit(
        'order/addShipmentsToOrders',
        response.data.map(label => ({ order_id: label.order_id, shipment_id: label.id })),
        { root: true },
      );

      // Return label IDs, so we can request PDF's if we want
      return response;
    }
  },
  async update({ dispatch }) {
    try {
      await api.update('courier');
    } finally {
      dispatch('reloadViews', { silent: false });
    }
  },
  async updateLabel({ commit }, label) {
    // For now, only deactivate works
    const result = await api.updateLabel(label);
    if (result.status === 'success') {
      commit('setLabel', result.data);
    }
  },
  async updateBulkLabels({ commit }, data) {
    const result = await api.updateBulkLabels(data);
    // TOTO: check if this works for lists aswell
    if (result.status === 'success') {
      commit('setLabels', result.labels);
    }
  },
  async packingSlipDoc(_, data) {
    const result = await api.packingSlipDoc(data);
    if (result.status === 'success') return result.file;
  },
  // Get/handle documents
  async getPdfLabels({ dispatch }, { labelIds, format, positions, sort }) {
    if (!Array.isArray(labelIds)) labelIds = [labelIds];
    const result = await api.getPdfLabels(labelIds, format, positions, sort);
    if (result.file) {
      dispatch('reloadViews', { silent: false });
      return result.file;
    }
  },
  async getExportDoc(context, { labelId }) {
    const result = await api.getExportDoc(labelId);
    if (result.status === 'success') return result.file;
  },
  async printLabels({ dispatch }, { labelIds, sort, format, positions, printerClientId }) {
    if (!Array.isArray(labelIds)) labelIds = [labelIds];

    const result = await api.printLabels(labelIds, printerClientId, format, positions, sort);
    if (result.status === 'success') dispatch('reloadViews', { silent: false });
  },
  // View controls
  async loadView_({ commit, dispatch, state }, { view, filter = undefined, silent = false }) {
    // Don't need to be loading more than once
    if (state.views[view].isLoading) return;

    if (!silent) commit('setViewIsLoading', { view, isLoading: true });

    commit('setViewFilter', { view, filter });
    const result = await api.getLabels(filter);

    if (silent) {
      commit('setViewUpdate', { view, data: result });
    } else {
      commit('setViewIsLoading', { view, isLoading: false });
      commit('setViewData', { view, data: result });
    }

    // TODO: We do not need to do this this often…
    dispatch('loadViewTotals', { view, filter });

    // Store copy for easy retrieval
    commit('setLabels', result.data);
  },
  async loadView({ dispatch }, { view, filter }) {
    await dispatch('loadView_', { view, filter, silent: false });
  },
  async loadViewTotals({ commit }, { view, filter }) {
    const result = await api.getLabelCount(filter);
    commit('setViewTotal', { view, total: result.data });
  },
  reloadView({ state, dispatch }, { view, silent = false }) {
    dispatch('loadView_', { view, silent, filter: state.views[view].filter });
  },
  reloadViews({ state, dispatch }, { silent = false }) {
    for (let view in state.views) dispatch('loadView_', { view, silent, filter: state.views[view].filter });
  },
  updateView({ commit, state }, { view }) {
    commit('setViewData', { view, data: state.views[view].update });
    commit('setViewUpdate', { view, data: null });
  },
};

const mutations = {
  setLabel(state, label) {
    const idx = state.all.findIndex(o => o.id === label.id);
    if (idx >= 0) state.all.splice(idx, 1);
    state.all.push(label);
  },
  setLabels(state, labels) {
    for (let label of labels) {
      const idx = state.all.findIndex(l => l.id === label.id);
      if (idx >= 0) state.all.splice(idx, 1);
      state.all.push(label);
    }
  },
  // View controls
  setViewData(state, { view, data }) {
    state.views[view].data = data;
  },
  setViewFilter(state, { view, filter }) {
    state.views[view].filter = filter;
  },
  setViewIsLoading(state, { view, isLoading }) {
    state.views[view].isLoading = isLoading;
  },
  setViewTotal(state, { view, total }) {
    state.views[view].total = total;
  },
  setViewUpdate(state, { view, data }) {
    if (hash(state.views[view].data) !== hash(data)) state.views[view].update = data;
    else state.views[view].update = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
