<template>
  <component :is="containerTag" :class="containerClass">
    <component
      :is="itemTag"
      v-for="(item, idx) in info"
      :key="idx"
      :class="shouldUseClass === true ? getClass(item) : ''"
    >
      <div class="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-px-4">
        {{ item | format }}
        <slot name="action" />
      </div>
    </component>
  </component>
</template>

<script>
  const STATUS_INFO_MAPPING = {
    missing_address_1: 'Adres is vereist',
    missing_housenumber: 'Huisnummer is vereist',
    missing_zipcode: 'Postcode is vereist',
    missing_city: 'Woontplaats is vereist',
    missing_country: 'Land is vereist',
    missing_email: 'E-mailadres is vereist',
    missing_street: 'Straatnaam is vereist',
    missing_currency: 'Valuta is vereist voor exportzendingen',
    missing_shipment_weight: 'Gewicht is vereist',
    missing_phone: 'Telefoonnummer order of organisatie ontbreekt',
    missing_order_number: 'Ordernummer is vereist',

    limit_phone_number: 'Telefoonnummer is te lang',
    limit_name: 'Naam is te lang of te kort',
    limit_order_number: 'Ordernummer is te lang',
    limit_address_1: 'Adresregel 1 is te lang',
    limit_housenumber: 'Huisnummer is te lang',
    limit_company_name: 'Bedrijfsnaam is te lang',
    limit_zipcode: 'Postcode is te lang',
    limit_city: 'Woontplaats is te lang',
    limit_country: 'Land is te lang',
    limit_email: 'E-mailadres is te lang',
    limit_org_email: 'E-mailadres organisatie is te lang',
    limit_street: 'Straatnaam is te lang',
    limit_shipment_weight: 'Gewicht van uw order is te zwaar: dit mag maximaal 31.5 kilo zijn',
    limit_number_suffix: 'Achtervoegsel huisnummer of adresregel 2 is te lang.',

    inactive_shipment_type: 'Verzendmethode niet beschikbaar',
    inactive_service_id: 'Verzendmethode niet beschikbaar',
    form_validation_courier_fields: 'Verzendopties niet volledig', // Don't split this for now, since we don't have full control (from frontend perspective) over these messages
    blocked_product_country: 'Land wordt niet ondersteund door Innosend',

    // order item fields
    no_order_items_order_items: 'ORDER ITEM: Geef tenminste één order item mee',
    validation_hs_code: 'Onjuiste formaat hs-code',
    missing_sku: 'ORDER ITEM: SKU-nummer is vereist',
    missing_hs_code: 'ORDER ITEM: HS-code is vereist',
    limit_hs_code: 'ORDER ITEM: lengte hs-code is te lang',
    missing_description: 'ORDER ITEM: Omschrijving is vereist',
    missing_weight: 'ORDER ITEM: Gewicht per oder item is vereist',
    missing_unit_price_inc_btw: 'ORDER ITEM: Prijs per order item is vereist',
    missing_product_country: 'ORDER ITEM: Land van herkomst is vereist',
    missing_total_weight: 'ORDER ITEM: Controleer gewicht en aantal',
    missing_total_price: 'ORDER ITEM: Controleer prijs en aantal',

    // company errors
    missing_company_characteristics: 'Ga naar instellingen om de bedrijfskenmerken in te vullen',
    missing_eori: 'Ga naar instellingen om EORI-nummer in te stellen',
    missing_eori_gb: 'Ga naar instellingen om EORI-nummer GB in te stellen',
    missing_vatin: 'Stel BTW-nummer in bij instellingen',
    missing_company_phone: 'Stel landelijke telefoonnummer in bij instellingen',
    missing_hmrc: 'Ga naar instellingen om HMRC in te vullen',
    limit_company_zipcode: 'Lengte postcode organisatie is te groot',

    validation_hmrc: 'Onjuiste formaat hmrc-code',
    validation_zipcode: 'Onjuiste formaat postcode',
    validation_vatin: 'Onjuiste formaat BTW-nummer',
    validation_org_phone: 'Controleer het nationaal telefoonnummer van je organisatie',
    validation_order_phone: 'Controleer het nationaal telefoonnummer van je order',
    validation_phone: 'Controleer het nationaal telefoonnummer van je order en organisatie',
    validation_eori: 'Onjuiste formaat EORI-nummer',

    // courier_fields
    not_allowed_courier_fields: 'Verzendopties zijn niet toegestaan bij gekozen verzendmethode',
    validation_courier_fields: 'Eén of meerdere verzendopties zijn niet toegestaan bij het ingevoerde adres',

    // other errors
    missing_weight_digitale_postzegel: 'Digitale postzegel heeft een gewicht nodig',
    validation_is_export:
      'Geselecteerde vervoerder ondersteund geen zendingen buiten de EU. Gebruik hiervoor DPD of UPS.',

    // warnings
    out_of_range_zipcode: 'Postcode buiten levergebied',
    no_validation_zipcode: 'Innosend is niet in staat om de postcode te valideren. Dit doet de vervoerder.',
  };

  export default {
    props: {
      info: Array,
      itemTag: {
        type: String,
        default: 'div',
      },
      itemClass: String,
      containerTag: {
        type: String,
        default: 'div',
      },
      containerClass: String,
      noClass: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      shouldUseClass() {
        return this.noClass === false;
      },
    },
    methods: {
      getClass(item) {
        const mapping = {
          error: 'is-danger',
          warning: 'is-warning',
          check_required: 'is-warning',
          info: 'is-info',
        };
        return item.status ? 'notification ' + mapping[item.status] : 'is-danger';
      },
    },
    filters: {
      format: function (value) {
        if (value.type === 'missing_shipment_logic_rule_value') return value.message;
        else if (value.type && value.field) return STATUS_INFO_MAPPING[`${value.type}_${value.field}`];
        else if (value.message) return value.message;
        return 'Onbekend';
      },
    },
  };
</script>
