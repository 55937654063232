<template>
  <main class="section home">
    <notifications />
    <div class="container">
      <div class="level">
        <div class="level-left" />
        <div class="level-right">
          <b-field v-if="user && user.organisation" class="has-dropdown">
            <p class="control">
              <b-button tag="router-link" :to="{ name: 'order_create' }">
                <div style="align-items: center; display: flex; gap: 8px">
                  <font-awesome-icon fixed-width icon="plus" />
                  Nieuwe zending
                </div>
              </b-button>
            </p>
            <p class="control">
              <b-dropdown position="is-bottom-left">
                <template #trigger>
                  <b-button>
                    <font-awesome-icon fixed-width icon="chevron-down" />
                  </b-button>
                </template>

                <b-dropdown-item aria-role="listitem" has-link>
                  <router-link :to="{ name: 'import' }">
                    <b-icon icon="software-upload" />
                    Upload CSV
                  </router-link>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="isTrial"
                  aria-role="listitem"
                  :disabled="isUpdatingTestOrders"
                  :loading="isUpdatingTestOrders"
                  @click="generateTestOrders()"
                >
                  <b-icon icon="file-add" />
                  Genereer test orders
                </b-dropdown-item>
              </b-dropdown>
            </p>
          </b-field>
        </div>
      </div>
      <b-tabs v-model="activeTab" type="is-boxed">
        <b-tab-item label="Bestellingen">
          <order-tab view-name="orderTab" />
        </b-tab-item>
        <b-tab-item label="Labels">
          <label-tab
            :default-sort="['created_at', 'desc']"
            :label-actions="['deactivate', 'print']"
            :label-filter="{ is_returned: false, status: ['printed', 'label'] }"
            label-timestamp="created_at"
            view-name="untrackedTab"
          />
        </b-tab-item>
        <b-tab-item label="Verzonden">
          <label-tab
            item-name="zending"
            item-name-plural="zendingen"
            :label-actions="['set_delivered', 'create_ticket']"
            :label-filter="{
              is_returned: false,
              status: [
                'transit',
                'courier',
                'depot',
                'delivery_failure',
                'return_from_delivery',
                'delivered_servicepoint',
              ],
            }"
            view-name="trackedTab"
          />
        </b-tab-item>
        <b-tab-item label="Bezorgd">
          <label-tab
            item-name="zending"
            item-name-plural="zendingen"
            :label-actions="['create_ticket']"
            :label-filter="{ is_returned: false, status: ['delivered', 'picked_up_servicepoint'] }"
            view-name="deliveredTab"
          />
        </b-tab-item>
        <b-tab-item label="Retouren">
          <return-tab :label-filter="{ is_returned: true }" label-timestamp="created_at" view-name="returnTab" />
        </b-tab-item>
        <b-tab-item label="Overige bestellingen">
          <other-tab :order-filter="{ status: ['other', 'pending'] }" view-name="otherTab" />
        </b-tab-item>
      </b-tabs>
    </div>
  </main>
</template>

<script>
  import { mapState } from 'vuex';
  import Notifications from '@/components/Notifications';
  import LabelTab from '@/components/tabs/LabelTab';
  import OrderTab from '@/components/tabs/OrderTab';
  import ReturnTab from '@/components/tabs/ReturnTab';
  import OtherTab from '@/components/tabs/OtherTab';
  // AVAILABLE_TABS is an array to preserve order
  const AVAILABLE_TABS = ['orders', 'labels', 'tracking', 'delivered', 'return', 'other', 'banaan'];
  const TAB_META = {
    orders: {
      resource: 'order',
      view: 'orderTab',
    },
    labels: {
      resource: 'label',
      view: 'untrackedTab',
    },
    tracking: {
      resource: 'label',
      view: 'trackedTab',
    },
    delivered: {
      resource: 'label',
      view: 'deliveredTab',
    },
    return: {
      resource: 'label',
      view: 'returnTab',
    },
    other: {
      resource: 'order',
      view: 'otherTab',
    },
  };

  export default {
    components: { LabelTab, OrderTab, Notifications, ReturnTab, OtherTab },
    props: {
      activeTabName: {
        type: String,
        default: 'orders',
      },
    },
    data() {
      return {
        activeTab: Math.max(AVAILABLE_TABS.indexOf(this.activeTabName), 0),
        updateIntervalId: null,
        isUpdatingTestOrders: false,
      };
    },
    computed: {
      ...mapState({
        user: state => state.account.user,
        organisation: state => state.account.organisation,
      }),
      isTrial() {
        return this.organisation?.plan?.capabilities?.trial ? this.organisation.plan.capabilities.trial : false;
      },
      console: () => console,
    },
    watch: {
      activeTab(newVal) {
        // This wil also ensure processTabSwitch is executed in beforeRouteEnter or beforeRouteUpdate
        this.$router.replace({ name: 'dashboard', params: { activeTabName: AVAILABLE_TABS[newVal] } });
      },
    },
    methods: {
      updateData() {
        this.$store.dispatch('order/reloadViews', { silent: true });
        this.$store.dispatch('label/reloadViews', { silent: true });
      },
      initBackgroundUpdate() {
        this.updateIntervalId = setInterval(() => this.updateData(), process.env.VUE_APP_POLL_INTERVAL * 1000);
      },
      async generateTestOrders() {
        this.isUpdatingTestOrders = true;
        await this.$store.dispatch('order/generateTestOrders');
        this.isUpdatingTestOrders = false;
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.initBackgroundUpdate();
      });
    },
    beforeRouteUpdate(to, from, next) {
      if (to.name === 'dashboard' && to.params?.activeTabName in TAB_META) {
        // Update data on tab switch
        const tab = TAB_META[to.params.activeTabName];
        this.$store.dispatch(`${tab.resource}/reloadView`, { view: tab.view, silent: true });
      }
      next();
    },
    beforeRouteLeave(to, from, next) {
      // Stop updating
      clearInterval(this.updateIntervalId);
      next();
    },
  };
</script>
