import { mapGetters } from 'vuex';
import { download } from '@/utils/functions';
import { printLabel, reportLabelError } from '@/utils/functions';
import { ApiError } from '@/utils/errors';
import TransferModal from '@/components/modals/TransferModal.vue';

export default {
  data() {
    return {
      printLabelMutex: false,
      isCreatingReturnLabel: false,
    };
  },
  computed: {
    ...mapGetters({
      printerClientIsReady: 'app/printerClientIsReady',
    }),

    canCreateReturnLabel: state => {
      switch (true) {
        case state?.courier?.service_name === 'DHL Innosend' && state.label?.country === 'FR':
          return false;
        case ['PostNL', 'MyParcel'].includes(state?.courier?.service_name) &&
          !['NL', 'BE'].includes(state.label?.country):
          return false;
        default:
          return true;
      }
    },
  },
  methods: {
    async printLabel(label, target = 'pdf') {
      this.printLabelMutex = true;
      try {
        printLabel({ labelIds: label.id, target });
      } finally {
        this.printLabelMutex = false;
      }
    },
    async createReturnLabel(label, target = 'pdf') {
      this.isCreatingReturnLabel = true;
      try {
        const result = await this.$store.dispatch('label/createLabels', {
          orderIds: [label.order_id],
          isReturn: true,
        });

        if (result.errors.length > 0) reportLabelError(result, 'Retour voor order');

        printLabel({ labelIds: result.data.map(l => l.id), target });
      } catch (error) {
        if (error instanceof ApiError) {
          reportLabelError(error.response, 'Retour order');
        } else {
          // TODO: Report
          throw error;
        }
      } finally {
        this.isCreatingReturnLabel = false;
      }
    },
    deactivateLabel(label, goBack = false) {
      this.$buefy.dialog.confirm({
        title: 'Label verwijderen?',
        message: `Weet je zeker dat je dit label (ordernummer ${label.order_number}) wilt verwijderen? Dit kan niet ongedaan gemaakt worden.`,
        cancelText: 'Annuleren',
        confirmText: 'Verwijderen',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$store.dispatch('label/updateLabel', {
              id: label.id,
              is_active: false,
            });
            this.$store.dispatch('label/reloadViews', { silent: false });
            this.$buefy.toast.open('Label verwijderd!');
            if (goBack) this.$router.back();
          } catch (e) {
            throw new ApiError();
          }
        },
      });
    },
    deactivateBulkLabels(selectedLabels) {
      const labelIds = selectedLabels.map(i => i.id);
      const orderNumbers = selectedLabels.map(i => i.order_number);
      this.$buefy.dialog.confirm({
        title: 'Order verwijderen?',
        message: `Weet je zeker dat je de labels: ${orderNumbers} wil verwijderen? Dit kan niet ongedaan worden`,
        cancelText: 'Annuleren',
        confirmText: 'Verwijderen',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$store.dispatch('label/updateBulkLabels', {
              label_ids: labelIds,
              action: 'deactivate',
            });
            this.$store.dispatch('label/reloadViews', { silent: false });
            this.$buefy.toast.open('Labels verwijderd!');
          } catch {
            throw new ApiError();
          }
        },
      });
    },
    deliverLabel(label, next = null) {
      this.$buefy.dialog.confirm({
        title: 'Label bezorgd?',
        message: `Weet je zeker dat je de status van dit label (ordernummer ${label.order_number}) op bezorgd wil zetten? Dit kan niet ongedaan gemaakt worden.`,
        cancelText: 'Annuleren',
        confirmText: 'Naar bezorgd',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$store.dispatch('label/updateLabel', {
              id: label.id,
              set_delivered: true,
            });
            this.$store.dispatch('label/reloadViews', { silent: false });
            this.$buefy.toast.open({ message: 'Labels bezorgd!', type: 'is-success' });
            if (next) this.$router.push(next);
          } catch {
            throw new ApiError();
          }
        },
      });
    },
    deliverBulkLabels(selectedLabels) {
      const labelIds = selectedLabels.map(i => i.id);
      const orderNumbers = selectedLabels.map(i => i.order_number);
      this.$buefy.dialog.confirm({
        title: 'Label bezorgd?',
        message: `Weet je zeker dat je de status van de labels: ${orderNumbers} op bezorgd wil zetten? Dit kan niet ongedaan gemaakt worden.`,
        cancelText: 'Annuleren',
        confirmText: 'Naar bezorgd',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$store.dispatch('label/updateBulkLabels', {
              label_ids: labelIds,
              action: 'deliver_labels',
            });
            this.$store.dispatch('label/reloadViews', { silent: false });
            this.$buefy.toast.open({ message: 'Labels bezorgd!', type: 'is-success' });
          } catch {
            throw new ApiError();
          }
        },
      });
    },
    async newOrderFromLabel(label) {
      const { shouldTransferOrderNumber, shouldTransferShipmentSettings } = await new Promise(resolve => {
        this.$buefy.modal.open({
          component: TransferModal,
          parent: this,
          hasModalCard: true,
          props: {
            onConfirm: async toTranfer => {
              resolve(toTranfer);
            },
          },
        });
      });

      const newOrder = {
        address_1: label.address_1,
        address_2: label.address_2,
        city: label.city,
        company_name: label.company_name,
        country: label.country,
        courier_id: label.service_id,
        currency: label.currency,
        email: label.email,
        housenumber: label.housenumber,
        name: label.name,
        order_items: label.order_items,
        order_number: shouldTransferOrderNumber ? label.order_number : null,
        phone: label.phone,
        shipment: {
          ...label.shipment,
          courier_fields: shouldTransferShipmentSettings ? label.shipment.courier_fields : null,
        },
        state_province_code: label.state_province_code,
        zipcode: label.zipcode,
      };
      this.$router.push({
        name: 'order_create',
        params: {
          prefilledData: newOrder,
          title: 'Label dupliceren',
          confirmText: 'Label dupliceren',
        },
      });
    },
    async generatePackingLists(selectedLabels) {
        const labelIds = selectedLabels.map(i => i.id);
        const orderNumbers = selectedLabels.map(i => i.order_number).join(", ");

        this.$buefy.dialog.confirm({
          title: 'Pakbonnen downloaden?',
          message: `Weet je zeker dat je pakbonnen voor de orders: ${orderNumbers} wil downloaden?`,
          cancelText: 'Annuleren',
          confirmText: 'Downloaden',
          type: 'is-success',
          hasIcon: true,
          onConfirm: () => this.downloadPackingSlips(labelIds),
        });
      },
      async downloadPackingSlips(labelIds) {
        try {
          this.isLoading = true;
          const pdf = await this.$store.dispatch('order/packingSlipDoc', { label_ids: labelIds });
          download('pakbon.pdf', pdf, 'application/pdf', 'base64');
          this.$buefy.toast.open('Pakbonnen gegenereerd');
          } 
          catch (error) {
            this.$buefy.toast.open('Er is een fout opgetreden bij het genereren van de pakbonnen.');
          } 
          finally {
            this.isLoading = false;
          }
      },
  },
};
