<template>
  <footer class="form-page-footer" :class="{ 'is-stuck': isStuck }">
    <slot></slot>
  </footer>
</template>

<script>
  export default {
    data() {
      return {
        isStuck: this.getIsStuck()
      };
    },
    methods: {
      getIsStuck() {
        /* NOTE:
         * This is a very naive implementation. It doesn't really detect if
         * the footer is "stuck" (in css position: sticky) terms, but just
         * whether the page has a scrollbar or not. As long as the footer is
         * the last visual element on the page, this is good enough.
         * Otherwise, this might introduce some visual bugs.
         *
         * I've once written a "StickyObserver" that, though not perfect,
         * performs a lot better in almost any situation. But for now, I'm
         * trying to avoid that pain… If this implementation turns out to be
         * in need of replacement, I can probably recycle that code.
         * -- Martijn Luinstra, 2021-05-31
         */
        return document.documentElement.scrollHeight > document.documentElement.clientHeight;
      },
      handleResize() {
        this.isStuck = this.getIsStuck();
      }
    },
    mounted() {
      this.handleResize();
      window.addEventListener('resize', this.handleResize.bind(this));
      // Hack to reset after navigation transition
      document.documentElement.addEventListener('transitionend', this.handleResize.bind(this));
    }
  };
</script>
