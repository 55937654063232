<template>
  <b-autocomplete
    id="state-province-code-autocomplete"
    append-to-body
    autocomplete="new-address-level1"
    :data="filteredStates"
    field="name"
    keep-first
    open-on-focus
    :value="model"
    @input="inputChanged"
    @select="optionSelected"
  >
    <template v-slot="{ option }">
      <option :id="`state-${option.isoCode}`">{{ option.name }}</option>
    </template>
    <template v-if="!filteredStates.length" #empty>Staat/Provincie niet gevonden</template>
  </b-autocomplete>
</template>

<script>
  import { State } from 'country-state-city';

  export default {
    emits: ['input', 'state-valid'],
    props: {
      /** the value of the state */
      value: {
        type: String,
        default: '',
      },
      /** the country iso code */
      country: {
        type: String,
        default: 'NL',
      },
    },
    data() {
      return {
        /** the entered value */
        model: '',
        /** the iso code of the selected state */
        isoCode: null,
      };
    },
    computed: {
      /** get all states of the selected country */
      states() {
        //enable only for Canada, USA and China
        if (['CA', 'US', 'CN'].includes(this.country)) return State.getStatesOfCountry(this.country);
        return [];
      },
      /** filter the states based on the entered value */
      filteredStates() {
        return this.states.filter(
          state =>
            state.name.toLowerCase().includes(this.model?.toLowerCase?.()) ||
            state.isoCode.toLowerCase() === this.model?.toLowerCase?.(),
        );
      },
    },
    methods: {
      /**
       * if the entered value is an existing state name, set the model to the state name
       * @param value
       */
      inputChanged(value) {
        const foundState = this.states.find(s => s.name.toLowerCase() === value.toLowerCase());
        if (foundState) {
          this.model = foundState.name;
          this.isoCode = foundState.isoCode;
          this.$emit('input', this.isoCode);
          this.$emit('state-valid', true);
        } else {
          this.model = value;
          this.isoCode = null;
          this.$emit('input', value);
          this.$emit('state-valid', false);
        }
      },
      /**
       * if an option is selected, set the model to the state name
       * @param option
       */
      optionSelected(option) {
        if (!option) return;
        this.model = option.name;
        this.isoCode = option.isoCode;
        this.$emit('input', this.isoCode);
        this.$emit('state-valid', true);
      },
    },
    watch: {
      /**
       * if the country changes check the validity of the state again
       */
      country: {
        immediate: true,
        handler() {
          this.inputChanged(this.model);
        },
      },
      /**
       * if the value prop changes, set the model to the state name
       */
      value: {
        immediate: true,
        handler(value) {
          const foundState = this.filteredStates.find(s => s.isoCode === value);

          if (foundState) {
            this.model = foundState.name;
          } else {
            this.model = value;
          }
        },
      },
    },
  };
</script>
